
import { Vue, Component, Prop, Watch, PropSync } from 'vue-property-decorator'
import { KpiValue } from '../../../../../types/brief_enum'
import * as rules from '../../../../rules/rules'
import FromToCalendar from '@/components/TableComponents/Form/Decks/FromToCalendar.vue'
import KpiToOptimizeDeck from '@/components/TableComponents/Form/Decks/KpiToOptimizeDeck.vue'
import EmailInputDeck from '@/components/TableComponents/Form/Decks/EmailInputDeck.vue'
import { InstructionDsp, SelectedIo } from '../../../../../types/instruction_type'
import { $YOUTUBE } from '../../../../../config/dspConfig'

const IGNORE_START_DATE_LIMIT_DSP: InstructionDsp[] = [$YOUTUBE]

@Component({
  components: { FromToCalendar, KpiToOptimizeDeck, EmailInputDeck }
})
export default class InsightForm extends Vue {
  @Prop({ required: false, default: null }) activeSelectedIos: SelectedIo
  @Prop({ required: true }) dsp: string
  @Prop({ required: true, default: false }) isOpenDialog: boolean
  @Prop({ required: true, default: '' }) kpiToOptimize: KpiValue

  @PropSync('startDateSync', { type: String, required: false, default: '' })
  startDate: string

  @PropSync('endDateSync', { type: String, required: false, default: '' })
  endDate: string

  @PropSync('emailSync', { type: String, required: true, default: '' })
  email: string

  @PropSync('selectedKpiSync', { required: true, default: '' })
  selectedKpi: KpiValue

  @PropSync('languageSync', { required: true, default: 'english' })
  language: string

  emailSearch: string = ''
  timeoutHandle: any = null

  languageOptions: TextValueType<string>[] = [
    { text: 'English', value: 'english' },
    { text: 'French', value: 'french' },
    { text: 'Portuguese', value: 'portuguese' },
    { text: 'Spanish', value: 'spanish' }
  ]

  rules = rules

  mounted () {
    this.resetForm(this.isOpenDialog)
  }

  resetForm (val: boolean) {
    if (val) {
      const today = new Date(Date.now())
      const twoWeeksAgo = new Date(Date.now() - 12096e5) // 2 weeks in millisecond notation
      this.startDate = this.$commonUtils.dateToIsoCalendar(twoWeeksAgo)
      this.endDate = this.$commonUtils.dateToIsoCalendar(today)
      this.selectedKpi = this.kpiToOptimize
      this.email = this.defaultEmail
    }
  }
  get selectedAmount (): number {
    return Object.keys(this.activeSelectedIos).length
  }
  get startDateErrorMessages (): string[] {
    let error: string[] = []
    const now: Date = new Date()
    const min: Date = new Date(now.setMonth(now.getMonth() - 3))
    if (!IGNORE_START_DATE_LIMIT_DSP.includes(this.dsp as InstructionDsp) && new Date(this.startDate) < min) {
      error.push('The start date can not be older than 3 months ago')
    }
    return error
  }

  get defaultEmail (): string {
    return this.$store.getters.getCurrentUserAvailableMail
  }

  @Watch('email')
  onEmailBufferChange (email: string) {
    if (this.timeoutHandle !== null) {
      clearTimeout(this.timeoutHandle)
    }
    this.timeoutHandle = window.setTimeout(() => {
      this.emailSearch = email
    }, 500)
  }

  @Watch('activeSelectedKpiList')
  setDefaultKpi () {
    this.selectedKpi = this.kpiToOptimize
  }

  @Watch('isOpenDialog')
  onOpenDialog (val: boolean) {
    this.resetForm(val)
  }
}

import { times } from 'lodash'
import {
  BulkRemoveScibidsApiArgs,
  RemoveScibidsRemovalParametersApiArgs,
  RemoveScibidsApplyStrategyApiArgs
} from '../../../types/remove_scibids_types'

export class RemoveScibidsApplyStrategyModel {
  comboSelected: number
  baseBid: number
  dspObjective: string
  dspObjectiveTarget: number

  constructor (data: RemoveScibidsApplyStrategyApiArgs = null) {
    if (data != null) {
      this.comboSelected = data.combo_selected
      this.baseBid = data.base_bid
      this.dspObjective = data.dsp_objective
      this.dspObjectiveTarget = data.dsp_objective_target
    } else {
      this.setDefault()
    }
  }
  setDefault () {
    this.comboSelected = null
    this.baseBid = null
    this.dspObjective = null
    this.dspObjectiveTarget = null
  }
  resetValues () {
    this.baseBid = null
    this.dspObjective = null
    this.dspObjectiveTarget = null
  }
  getAsApiArgs (): RemoveScibidsApplyStrategyApiArgs {
    const toReturn: RemoveScibidsApplyStrategyApiArgs = {
      combo_selected: this.comboSelected,
      base_bid: this.baseBid,
      dsp_objective: this.dspObjective,
      dsp_objective_target: this.dspObjectiveTarget
    }
    let cleanedToReturn: RemoveScibidsApplyStrategyApiArgs = {}
    // rm empty value
    for (let key in toReturn) {
      if (toReturn[key as keyof RemoveScibidsApplyStrategyApiArgs] !== null &&
        toReturn[key as keyof RemoveScibidsApplyStrategyApiArgs] !== undefined &&
        toReturn[key as keyof RemoveScibidsApplyStrategyApiArgs] !== '') {
        // @ts-ignore
        cleanedToReturn[key as keyof RemoveScibidsApplyStrategyApiArgs] = toReturn[key as keyof RemoveScibidsApplyStrategyApiArgs]
      }
    }
    return cleanedToReturn
  }
}

export class RemoveScibidsRemovalParametersModel {
  deactivateAfterRemove: boolean
  applyStrategy: RemoveScibidsApplyStrategyModel

  constructor (data: RemoveScibidsRemovalParametersApiArgs = null) {
    if (data != null) {
      this.deactivateAfterRemove = data.deactivate_after_remove
      this.applyStrategy = new RemoveScibidsApplyStrategyModel(data.apply_strategy)
    } else {
      this.setDefault()
    }
  }
  setDefault () {
    this.deactivateAfterRemove = false
    this.applyStrategy = new RemoveScibidsApplyStrategyModel()
  }
  getAsApiArgs (): RemoveScibidsRemovalParametersApiArgs {
    const toReturn: RemoveScibidsRemovalParametersApiArgs = {
      deactivate_after_remove: this.deactivateAfterRemove,
      apply_strategy: this.applyStrategy.getAsApiArgs()
    }
    let cleanedToReturn: RemoveScibidsRemovalParametersApiArgs = {}
    // rm empty value
    for (let key in toReturn) {
      if (toReturn[key as keyof RemoveScibidsRemovalParametersApiArgs] !== null &&
        toReturn[key as keyof RemoveScibidsRemovalParametersApiArgs] !== undefined) {
        // @ts-ignore
        cleanedToReturn[key as keyof RemoveScibidsRemovalParametersApiArgs] = toReturn[key as keyof RemoveScibidsRemovalParametersApiArgs]
      }
    }
    return cleanedToReturn
  }
}

export default class BulkRemoveScibidsModel {
  ioIds: string[]
  removalReason: string
  removalParameters: RemoveScibidsRemovalParametersModel

  constructor (data: BulkRemoveScibidsApiArgs = null) {
    if (data != null) {
      this.ioIds = data.io_ids
      this.removalReason = data.removal_reason
      this.removalParameters = new RemoveScibidsRemovalParametersModel(data.removal_parameters)
    } else {
      this.setDefault()
    }
  }
  setDefault () {
    this.ioIds = []
    this.removalReason = null
    this.removalParameters = new RemoveScibidsRemovalParametersModel()
  }
  getAsApiArgs (): BulkRemoveScibidsApiArgs {
    const toReturn: BulkRemoveScibidsApiArgs = {
      io_ids: this.ioIds,
      removal_reason: this.removalReason,
      removal_parameters: this.removalParameters.getAsApiArgs()
    }

    let cleanedToReturn: BulkRemoveScibidsApiArgs = {}
    // rm empty value
    for (let key in toReturn) {
      if (toReturn[key as keyof BulkRemoveScibidsApiArgs] !== null &&
        toReturn[key as keyof BulkRemoveScibidsApiArgs] !== undefined &&
        toReturn[key as keyof BulkRemoveScibidsApiArgs] !== '') {
        // @ts-ignore
        cleanedToReturn[key as keyof BulkRemoveScibidsApiArgs] = toReturn[key as keyof BulkRemoveScibidsApiArgs]
      }
    }

    return cleanedToReturn
  }
}


import { defineComponent } from 'vue'
import { $YOUTUBE } from '../../../../../config/dspConfig'
import _ from 'lodash'

type DspCheckResult = { subDsp: string | null, errors: string[] }

type InfoErrorType = Record<string, Record<string, any>>
type ErrorType = Record<string, InfoErrorType> | string

const SIMULATION_SECOND_BY_LINE = 0.14
const UPLOAD_SECOND_BY_LINE = 0.24

export default defineComponent({
  name: 'BulkCsvModal',
  props: {
    value: Boolean
  },
  data: () => ({
    file: null,
    isUploading: false,
    isSimulating: false,
    simulationErrors: [] as string[][],
    csvText: '',
    allowedDspValues: [
      $YOUTUBE
    ] as string[],
    maxNumberOfLines: 500 as Readonly<number>,
    urlTemplate: 'https://docs.google.com/spreadsheets/d/1asRSM3yQY5E6luGnDG_7r-EsWtdYk4ajYdF9tQ7ot9I/edit',
    urlDoc: 'https://scibids-k.atlassian.net/wiki/spaces/AM/pages/2352676866/.csv+to+bulk+edit+instructions',
    secondSinceLoading: 0,
    isDone: false,
    intervalLoadingBarId: null as NodeJS.Timeout | null
  }),
  methods: {
    async upload () {
      this.isUploading = true
      this.isDone = false

      const parsedCsv = this.parseCsv(this.csvText)
      const result = this.checkCsv(parsedCsv)

      this.startLoadingInterval()
      const response = await this.$apiCaller.postBatchCsv(this.csvText, result.subDsp)
      this.isDone = true

      setTimeout(() => {
        if (this.$apiCaller.isResponseError(response)) {
          this.$store.commit('setErrorMessageWithResponse', response)
        } else {
          this.$store.commit('setSuccessMessage', 'CSV uploaded successfully')
          this.close()
        }
        this.isUploading = false
      }, 1000)
    },
    async simulateUpload () {
      this.isSimulating = true
      this.isDone = false
      this.simulationErrors = []

      const parsedCsv = this.parseCsv(this.csvText)
      const result = this.checkCsv(parsedCsv)

      if (result.errors.length > 0) {
        this.simulationErrors = [result.errors]
        this.isSimulating = false
        return
      }

      this.startLoadingInterval()
      const response = await this.$apiCaller.postSimulateBatchCsv(this.csvText, result.subDsp)
      this.isDone = true

      setTimeout(() => {
        if (this.$apiCaller.isResponseError(response)) {
          const errors = response?.data?.errors ? response.data.errors : response.response.data.errors
          const errorMessage = `Error when simulating the upload: \n ${this.manageDisplayErrors(errors)}`
          this.simulationErrors = [[errorMessage]]
        } else {
          this.simulationErrors = Object.values(response.data.errors)
        }
        this.isSimulating = false
      }, 1000)
    },
    manageDisplayErrors (
      errors: ErrorType): string {
      const createErrorString = (_errors: Record<string, any>, ioId: string, fieldName: string): string => {
        let _errorStr = ''
        if (_.isArray(_errors[0][fieldName])) {
          _errorStr += `[${ioId}] : ${fieldName} ${_errors[0][fieldName].join(', ')}\n`
        } else if (_.isObject(_errors[0][fieldName])) {
          if ('_schema' in _errors[0][fieldName]) {
            _errorStr += `[${ioId}] : ${fieldName} ${_errors[0][fieldName]['_schema'].join(', ')}\n`
          } else {
            for (const key in _errors[0][fieldName]) {
              _errorStr += `[${ioId}] : ${fieldName} ${key} ${JSON.stringify(_errors[0][fieldName][key])}\n`
            }
          }
        } else {
          _errorStr += `[${ioId}] : ${fieldName} ${_errors[0][fieldName]}\n`
        }
        return _errorStr
      }
      if (_.isObject(errors)) {
        // if we are here, it means that we are with schema validation errors (object)
        let errorStr = ''
        for (const ioId in errors) {
          for (const fieldName in errors[ioId][0]) {
            errorStr += createErrorString(errors[ioId], ioId, fieldName)
          }
        }
        return errorStr
      } else if (_.isString(errors)) {
        return errors
      }
      return errors
    },
    close (): void {
      this.dialog = false
      this.resetValues()
    },
    resetValues () {
      this.file = null
      this.isUploading = false
      this.isSimulating = false
      this.simulationErrors = []
      this.csvText = ''
    },
    isDisabledUpload (): boolean {
      return !this.file || this.isUploading ||
        this.isSimulating || !this.isSimulationSuccess
    },
    parseCsv (csv: string): string[][] {
      const data = csv.split('\n')
      let lines = this.parseLines(data)

      // check if the first line has less than 2 column, if yes, may be the file use another separator
      if (lines[0].length < 2) {
        lines = this.parseLines(data, ';')
      }

      return lines
    },
    parseLines (data: string[], separator: string = ','): string[][] {
      if (separator.length !== 1) {
        throw new Error('Separator must be a single character')
      }
      const lines = []
      for (const line of data) {
        lines.push(line.trim().split(separator))
      }
      return lines
    },
    checkCsv (csv: string[][]): DspCheckResult {
      const errors = []
      let dspValue: string | null = null
      // 0. remove empty lines
      const csvCleared = csv.filter(row => row.filter(value => value.trim() !== '').length > 0)
      // 1. check if dsp column is filled with the same value for all rows
      // a. get the number of the dsp column
      const columns = csvCleared[0]
      const dspColumnIndex = columns.indexOf('dsp')
      if (dspColumnIndex === -1) {
        errors.push('dsp column is missing')
      } else {
        // b. check if all rows have the same value for the dsp column
        let dspValues = csvCleared.map(row => row[dspColumnIndex])
        dspValues.shift()
        dspValue = dspValues[0]

        if (!this.allowedDspValues.includes(dspValue)) {
          errors.push(`dsp column has invalid value. Allowed values are ${this.allowedDspValues.join(', ')}`)
        } else if (dspValues.some(value => value !== dspValue)) {
          const valueError = dspValues.find(value => value !== dspValue)
          const valueIndex = dspValues.indexOf(valueError)
          const columnNumber = valueIndex + 2 // +2 because we have to count the header row and the index is 0-based
          const errorMessageValueShouldBeTheSame = `dsp column has different values. All dsp values should be the same.
          The value should be ${dspValue}. Reveived value: ${valueError}. Column number: ${columnNumber}`
          errors.push(errorMessageValueShouldBeTheSame)
        }
      }

      // 2. check the max number of columns
      const nbLines = csvCleared.length

      // +1 because we have to count the header row
      if (nbLines > (this.maxNumberOfLines + 1)) {
        errors.push(`The number of lines exceeds the maximum allowed number of lines: ${this.maxNumberOfLines}`)
      }

      return {
        subDsp: dspValue,
        errors: errors
      }
    },
    startLoadingInterval () {
      if (this.intervalLoadingBarId) {
        clearInterval(this.intervalLoadingBarId)
      }
      this.secondSinceLoading = 0
      this.intervalLoadingBarId = setInterval(() => {
        this.secondSinceLoading += 1
      }, 1000)
    }
  },
  computed: {
    dialog: {
      get (): boolean {
        return this.value
      },
      set (value: boolean) {
        this.$emit('input', value)
      }
    },
    isLoading (): boolean {
      return this.isUploading || this.isSimulating
    },
    isSimulationSuccess (): boolean {
      return this.simulationErrors.length === 0
    },
    load (): number {
      const totalSecond = this.csvText.split('\n').length * (this.isSimulating ? SIMULATION_SECOND_BY_LINE : UPLOAD_SECOND_BY_LINE)
      return this.isDone ? 100 : Math.min(99, this.secondSinceLoading / totalSecond * 100)
    }
  },
  watch: {
    file: {
      async handler () {
        this.csvText = await this.file.text()
        this.simulateUpload()
      }
    }
  }
})

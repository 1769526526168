<template>
  <BaseWarningDialog
    v-model="dialog"
    :warning-list="warningsListByDsp"
    @warning-checked="emitWarningChecked"
    label-cancel="Cancel and edit instruction"
    :key="dialog"
  >
  </BaseWarningDialog>
</template>

<script>
import { mapMutations } from 'vuex'
import BaseWarningDialog from '@/components/Common/BaseWarningDialog.vue'
import { isActivatedCheckPreMep } from '../../../../../config/featureRefHelper'
import NewFeatureReferenceModel from '@/models/Keystone_v2/NewFeatureReferenceModel'

export default {
  name: 'WarningDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    notValidOffset: {
      type: Array
    },
    warningList: {
      type: Object,
      required: true,
      default: () => {
        return {
          names: [],
          errors: []
        }
      }
    }
  },
  components: {
    BaseWarningDialog
  },
  data: function () {
    return {
      dialog: false,
      allDsp: [this.$MEDIAMATH, this.$DBM, this.$APPNEXUS, this.$YOUTUBE, this.$THETRADEDESK, this.$BEESWAX, this.$BEESWAX, this.$KAYZEN, this.$META, this.$GOOGLEADS],
      warnings: [
        {
          name: 'warningKpiToOptimizeOffset',
          isEnabled: [this.$APPNEXUS, this.$DBM, this.$MEDIAMATH, this.$BEESWAX, this.$THETRADEDESK, this.$KAYZEN]
        },
        {
          name: 'warningMaxAOtto',
          isEnabled: [this.$APPNEXUS, this.$DBM, this.$MEDIAMATH, this.$BEESWAX, this.$YOUTUBE, this.$THETRADEDESK, this.$KAYZEN]
        },
        {
          name: 'warningKpiToOptimizeMaxCpm',
          isEnabled: [this.$APPNEXUS, this.$DBM, this.$MEDIAMATH, this.$THETRADEDESK, this.$BEESWAX, this.$KAYZEN]
        },
        {
          name: 'warningOnlyMustBeEqualToAllbut',
          isEnabled: [this.$APPNEXUS, this.$DBM, this.$MEDIAMATH, this.$THETRADEDESK, this.$YOUTUBE, this.$FACEBOOK, this.$META]
        },
        {
          name: 'warningAllLineInOnlyButNoAllbut',
          isEnabled: [this.$APPNEXUS, this.$DBM, this.$MEDIAMATH, this.$THETRADEDESK, this.$YOUTUBE, this.$FACEBOOK, this.$META]
        },
        {
          name: 'warningGroupKeyNotValid',
          isEnabled: [this.$APPNEXUS, this.$DBM, this.$MEDIAMATH, this.$THETRADEDESK, this.$YOUTUBE, this.$FACEBOOK, this.$BEESWAX, this.$KAYZEN, this.$META, this.$GOOGLEADS]
        },
        {
          name: 'warningClientIdNotInDspConfig',
          isEnabled: [this.$APPNEXUS, this.$DBM, this.$MEDIAMATH, this.$THETRADEDESK, this.$YOUTUBE, this.$FACEBOOK, this.$BEESWAX, this.$KAYZEN, this.$META, this.$GOOGLEADS]
        },
        {
          name: 'warningConfigStatusNotComplete',
          isEnabled: [this.$APPNEXUS, this.$DBM, this.$MEDIAMATH, this.$THETRADEDESK, this.$YOUTUBE, this.$FACEBOOK, this.$BEESWAX, this.$KAYZEN, this.$META, this.$GOOGLEADS]
        },
        {
          name: 'warningMaxCutoffKpiToOptimize',
          isEnabled: [this.$APPNEXUS, this.$DBM, this.$MEDIAMATH, this.$THETRADEDESK, this.$BEESWAX, this.$KAYZEN]
        },
        {
          name: 'warningForceMinBudget',
          isEnabled: [this.$APPNEXUS, this.$DBM, this.$MEDIAMATH, this.$THETRADEDESK, this.$BEESWAX, this.$KAYZEN]
        },
        {
          name: 'warningBaseline',
          isEnabled: [this.$APPNEXUS, this.$DBM, this.$MEDIAMATH, this.$THETRADEDESK, this.$YOUTUBE, this.$FACEBOOK, this.$BEESWAX, this.$KAYZEN]
        },
        {
          name: 'warningOttoColdStart',
          isEnabled: [this.$MEDIAMATH, this.$DBM, this.$APPNEXUS, this.$YOUTUBE, this.$THETRADEDESK, this.$BEESWAX]
        },
        {
          name: 'warningMaxAIncreaseTooHigh',
          isEnabled: [this.$MEDIAMATH, this.$DBM, this.$APPNEXUS, this.$YOUTUBE, this.$THETRADEDESK, this.$BEESWAX, this.$BEESWAX, this.$KAYZEN]
        },
        {
          name: 'warningOttoColdStartAndPerfFirst',
          isEnabled: [this.$MEDIAMATH, this.$DBM, this.$APPNEXUS, this.$YOUTUBE, this.$THETRADEDESK, this.$BEESWAX, this.$BEESWAX, this.$KAYZEN]
        },
        {
          name: 'warningRevenueTypeConstraintsPerfFirst',
          isEnabled: [this.$MEDIAMATH, this.$DBM, this.$APPNEXUS, this.$YOUTUBE, this.$THETRADEDESK, this.$BEESWAX, this.$BEESWAX, this.$KAYZEN]
        },
        {
          name: 'warningMinVizFilter',
          isEnabled: [this.$DBM, this.$APPNEXUS, this.$THETRADEDESK]
        },
        {
          name: 'warningUseCustomAlgoDeactivatedAsDebugger',
          isEnabled: [this.$DBM]
        },
        {
          name: 'warningValidatedButCheckPreMepNotValid',
          isEnabled: () => {
            const allDsp = this.allDsp
            const newFeaturesRef = this.$store.getters.getCurrentNewFeatureReferences
            return allDsp.filter(dsp => isActivatedCheckPreMep(newFeaturesRef, dsp))
          }
        },
        {
          name: 'warningCheckPreMepBlockingEditable',
          isEnabled: () => {
            const allDsp = this.allDsp
            const newFeaturesRef = this.$store.getters.getCurrentNewFeatureReferences
            return allDsp.filter(dsp => isActivatedCheckPreMep(newFeaturesRef, dsp))
          }
        },
        {
          name: 'warningForceAsapPacingMoney',
          isEnabled: [this.$MEDIAMATH]
        },
        {
          name: 'warningYoutubeForActionOptiAuto',
          isEnabled: [this.$YOUTUBE]
        },
        {
          name: 'warningYoutubeSurcoucheRatioOttoDeactivated',
          isEnabled: [this.$YOUTUBE]
        },
        {
          name: 'warningThirdPartyConstraintsOptiRatio',
          isEnabled: [this.$DBM, this.$APPNEXUS, this.$THETRADEDESK, this.$BEESWAX]
        },
        {
          name: 'warningOttoMinVizTheTradeDesk',
          isEnabled: [this.$THETRADEDESK]
        }
      ]
    }
  },
  mounted () {
    this.dialog = this.value
  },
  methods: {
    ...mapMutations(['setInstructionsInWarning', 'clearInstructionsInWarning']),
    emitWarningChecked () {
      this.clearInstructionsInWarning()
      this.$emit('warning-checked')
    }
  },
  watch: {
    value (val) {
      if (val !== this.dialog) {
        this.dialog = val
      }
    },
    dialog (val) {
      this.$emit('input', val)
      if (this.dialog) {
        this.setInstructionsInWarning(this.notValidOffset)
      }
    }
  },
  computed: {
    warningsListByDsp () {
      let dsp = this.$route.params.dsp
      const warningsListByDsp = {
        names: [],
        errors: []
      }

      for (let name of this.warningList.names) {
        const warningConf = this.warnings.find(item => item.name === name)
        let isEnabled = warningConf.isEnabled

        if (typeof warningConf.isEnabled === 'function') {
          isEnabled = warningConf.isEnabled()
        }

        if (isEnabled.includes(dsp)) {
          const index = this.warningList.names.indexOf(name)
          if (index !== -1) {
            warningsListByDsp.names.push(name)
            warningsListByDsp.errors.push(this.warningList.errors[index])
          } else {
            console.warn('Can not find index.')
          }
        }
      }
      return warningsListByDsp
    }
  }
}
</script>

<style scoped>

</style>


import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { InstructionDsp, SelectedIo } from '../../../../../types/instruction_type'
import BulkRemoveScibidsModel from '../../../../models/RemoveScibids/RemoveScibidsModel'
import RemoveScibidsForm from './RemoveScibidsForm.vue'
import SimpleDialog from './SimpleDialog.vue'

@Component({
  components: {
    SimpleDialog,
    RemoveScibidsForm
  }
})
export default class RemoveScibidsDialog extends Vue {
  @Prop({ required: false, default: false }) removeScibidsDialog: boolean
  @Prop({ required: true }) removeScibidsModel: BulkRemoveScibidsModel
  @Prop({ required: true }) selected: SelectedIo
  @Prop({ required: true }) dsp: InstructionDsp

  dialogOn: boolean = false

  close () {
    this.$emit('close')
  }

  removeIoFromList (ioId: string) {
    this.$emit('remove-io', ioId)
  }

  @Watch('removeScibidsDialog')
  onRemoveScibidsDialogChange () {
    this.dialogOn = this.removeScibidsDialog
  }
}

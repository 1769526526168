
import Component, { mixins } from 'vue-class-component'
import { KpiValue, KpiText } from '../../../../../types/brief_enum'
import { rulesMixin } from '../../../../mixins/rulesMixin'
import BulkRemoveScibidsModel, { RemoveScibidsApplyStrategyModel } from '../../../../models/RemoveScibids/RemoveScibidsModel'
import { $APPNEXUS, $BEESWAX, $DBM, $THETRADEDESK, $YOUTUBE } from '../../../../../config/dspConfig'
import { Prop, Watch } from 'vue-property-decorator'
import { Instruction, InstructionDsp, SelectedIo } from '../../../../../types/instruction_type'
import FullBulkInstructionAutocomplete
  from '@/components/TableComponents/Form/BulkCommon/FullBulkInstructionAutocomplete.vue'
import BulkOttoVMenu from '@/components/TableComponents/Form/BulkOtto/BulkOttoVMenu.vue'

export type applyStratCombosType = { fields: Array<string>, rule: () => boolean }

export enum Combos {
  BASE_BID = 0,
  KPI = 1,
  KPI_AND_TARGET = 2,
  ALL_OF_THEM = 3
}

@Component({
  components: { BulkOttoVMenu, FullBulkInstructionAutocomplete }
})
export default class RemoveScibidsForm extends mixins(rulesMixin) {
  @Prop({ required: true }) selected: SelectedIo
  @Prop({ required: true }) dsp: InstructionDsp
  @Prop({ required: true }) removeScibidsModel: BulkRemoveScibidsModel

  removalReasonItems: Array<{ [key: string]: any }> = [
    { text: 'Constraints Not Respected', value: 'CONSTRAINTS_NOT_RESPECTED' },
    { text: 'Pacing / Delivery', value: 'PACING_DELIVERY' },
    { text: 'KPI Not Reached', value: 'KPI_NOT_REACHED' },
    { text: 'Low Margins', value: 'LOW_MARGINS' },
    { text: 'Other', value: 'OTHER' }
  ]
  applyStratComboItems: { [key: string]: Array<{ text: string, value: number }> } = {
    [$APPNEXUS]: [
      { text: 'Default Xandr CPM', value: null },
      { text: 'Edit KPI', value: Combos.KPI_AND_TARGET }
    ],
    [$DBM]: [
      { text: 'Default DBM CPC', value: null },
      { text: 'Edit Base Bid', value: Combos.BASE_BID },
      { text: 'Edit KPI', value: Combos.KPI }
    ],
    [$YOUTUBE]: [
      { text: 'No optimization change', value: null }
    ],
    [$THETRADEDESK]: [
      { text: 'No optimization change', value: null },
      { text: 'Edit Base Bid', value: Combos.BASE_BID },
      { text: 'Edit KPI', value: Combos.KPI_AND_TARGET },
      { text: 'Edit Base Bid & KPI', value: Combos.ALL_OF_THEM }
    ],
    [$BEESWAX]: [
      { text: 'No optimixzation change', value: null },
      { text: 'Edit Base Bid', value: Combos.BASE_BID }
    ]
  }
  dspObjectiveItems: { [key: string]: Array<any> } = {
    [$APPNEXUS]: [
      { text: KpiText.VTR, value: KpiValue.VTR },
      { text: KpiText.CTR, value: KpiValue.CTR },
      { text: KpiText.CPC, value: KpiValue.CPC },
      { text: KpiText.CPCV, value: KpiValue.CPCV },
      { text: KpiText['V-CPM'], value: KpiValue.V_CPM }
    ],
    [$DBM]: [
      { text: KpiText.CPA, value: KpiValue.CPA },
      { text: KpiText.CPC, value: KpiValue.CPC },
      { text: KpiText['V-CPM'], value: KpiValue.V_CPM },
      { text: KpiText.CPIAVC, value: KpiValue.CPIAVC }
    ],
    [$YOUTUBE]: [],
    [$THETRADEDESK]: [
      { text: KpiText.VTR, value: KpiValue.VTR },
      { text: KpiText.CTR, value: KpiValue.CTR },
      { text: KpiText.CPA, value: KpiValue.CPA },
      { text: KpiText.CPC, value: KpiValue.CPC },
      { text: KpiText.CPCV, value: KpiValue.CPCV },
      { text: KpiText['V-CPM'], value: KpiValue.V_CPM }
    ],
    [$BEESWAX]: []
  }
  errorWhenApply: boolean = false
  isUserAllowed: boolean = false

  showAutocomplete: boolean = false

  ioFromAutocomplete: SelectedIo = {}
  private loadingInstructionList: boolean

  mounted () {
    this.setTmpAccess()
  }

  // TMP FUNCTION TO CHECK ACCESS BEFORE THE FEATURE IS COMPLETELY DONE (AUGUST 2023 - (?))
  setTmpAccess () {
    const roles = this.$store.getters.getCurrentUserRoles
    this.isUserAllowed = roles.includes('api.v1.remove_scibids.dsp.member.insertion_order:post')
  }

  async apply () {
    if (!this.checkRequiredField()) {
      this.errorWhenApply = true
      return false
    }
    this.errorWhenApply = false
    await this.sendRemoveScibidsBulkPostRequest()
    this.close()
  }
  close () {
    this.$emit('close')
  }

  // REQUEST
  async sendRemoveScibidsBulkPostRequest () {
    this.removeScibidsModel.ioIds = this.ioList
    const response = await this.$apiCaller.postBulkRemoveScibids(
      this.dsp,
      this.removeScibidsModel.getAsApiArgs()
    )
    if (this.$apiCaller.isResponseError(response)) {
      this.$store.commit('setErrorMessageWithResponse', response)
    } else {
      this.$store.commit('setSuccessMessageWithResponse', response.data[0].message)
    }
  }

  checkRequiredField (): boolean {
    const checkers = [
      [this.isBaseBidRequired, this.removeScibidsModel.removalParameters.applyStrategy.baseBid],
      [this.isDspObjectiveRequired, this.removeScibidsModel.removalParameters.applyStrategy.dspObjective],
      [this.isDspTargetRequired, this.removeScibidsModel.removalParameters.applyStrategy.dspObjectiveTarget]
    ]
    let check: boolean = true
    checkers.forEach((fn: Array<any>) => {
      if (fn[0] && fn[1] == null) {
        check = false
      }
    })
    return check && this.removeScibidsModel.removalReason != null
  }

  async addIoIdToBulk (ioIds: string[]) {
    const data = await this.getInstructionsList(ioIds)
    const ioField = this.$dspConfig[this.dsp as InstructionDsp].newIoField
    let toAddInIoFromAutocomplete: SelectedIo = {}
    if (data) {
      toAddInIoFromAutocomplete = data.reduce((acc: SelectedIo, instruction: Instruction) => {
        const ioValue: string = instruction[ioField as StringKeys<Instruction>]

        if (ioValue in this.ioFromAutocomplete) {
          return acc
        }

        acc[ioValue] = {
          state: true,
          instruction
        }
        return acc
      }, {})
      this.ioFromAutocomplete = { ...this.ioFromAutocomplete, ...toAddInIoFromAutocomplete }
    }
  }

  async getInstructionsList (ioIds: string[]): Promise<Instruction[] | null> {
    this.loadingInstructionList = true
    let data = null
    const response = await this.$apiCaller.getInstructions({
      io_list: ioIds.join(','),
      dsp: this.dsp
    })

    if (this.$apiCaller.isResponseError(response)) {
      this.$store.commit('setErrorMessage', 'Failed to fetch instructions')
    } else {
      data = response.data
    }
    this.loadingInstructionList = false
    return data
  }

  removeIoFromList (ioId: string) {
    if (ioId in this.ioFromAutocomplete) {
      const newIoFromAutocomplete = { ...this.ioFromAutocomplete }
      delete newIoFromAutocomplete[ioId]
      this.ioFromAutocomplete = newIoFromAutocomplete
    } else {
      this.$emit('remove-io', ioId)
    }
  }

  get activeSelectedIos (): SelectedIo {
    const asArray = Object.entries(this.selected)
    const filtered = asArray.filter(([key, value]) => (value.state === true))
    return { ...Object.fromEntries(filtered), ...this.ioFromAutocomplete }
  }

  get ioList (): string[] {
    return Object.keys(this.activeSelectedIos)
  }

  get selectedAmount (): number {
    return this.ioList.length
  }

  // GETTERS
  get getDspObjectiveItemsChoice (): Array<any> {
    return this.dspObjectiveItems[this.dsp]
  }
  get isApplyStratComboSelected (): boolean {
    return this.removeScibidsModel.removalParameters.applyStrategy.comboSelected != null
  }
  get isBaseBidRequired (): boolean {
    const comboSelected = this.removeScibidsModel.removalParameters.applyStrategy.comboSelected
    return this.isApplyStratComboSelected != null && [Combos.BASE_BID, Combos.ALL_OF_THEM].includes(comboSelected)
  }
  get isDspObjectiveRequired (): boolean {
    const comboSelected = this.removeScibidsModel.removalParameters.applyStrategy.comboSelected
    return this.isApplyStratComboSelected != null && [Combos.KPI, Combos.KPI_AND_TARGET, Combos.ALL_OF_THEM].includes(comboSelected)
  }
  get isDspTargetRequired (): boolean {
    const comboSelected = this.removeScibidsModel.removalParameters.applyStrategy.comboSelected
    return this.isApplyStratComboSelected != null && [Combos.KPI_AND_TARGET, Combos.ALL_OF_THEM].includes(comboSelected)
  }
  get isKpiSelectedInPercentageMode (): boolean {
    const applyStrat = this.removeScibidsModel.removalParameters.applyStrategy
    return applyStrat != null && applyStrat.dspObjective != null && [KpiValue.VTR as string, KpiValue.CTR as string].includes(applyStrat.dspObjective)
  }

  // WATCHERS
  @Watch('removeScibidsModel.removalParameters.applyStrategy.comboSelected')
  onComboSelectedChange () {
    const applyStratModel: RemoveScibidsApplyStrategyModel = this.removeScibidsModel.removalParameters.applyStrategy
    if (applyStratModel != null) {
      applyStratModel.resetValues()
    }
  }
}
